<template>
  <div>
    <Header />
    <pageTitle txt="追加発注 一覧" :backBtn="backBtn" backURL="/iseya/home/" />
    <div id="wrapper">
      <ul class="list">
        <li class="list-item" v-for="store in stores" :key="store.id">
          <orderItem
            :id="store.id"
            :date="ymdFormat(date)"
            :name="store.name"
            :tel="store.tel"
            :period="startDate + '～' + endDate"
            :unread="store.is_read"
            :extra="true"
            url="/iseya/order/extraDetail/"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
#wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  box-sizing: border-box;
  padding: 0 15px 20px;
}

.list {
  box-sizing: border-box;
  border-left: solid 1px variables.$bg-gray-2;
  border-right: solid 1px variables.$bg-gray-2;
  @extend .f_body;
  margin-bottom: 20px;
}
</style>

<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import orderItem from "@/components/iseya/orderItem";
import Global from "@/components/mixins/Global.vue";
import OrderReaded from "@/components/mixins/OrderReaded.vue";

export default {
  name: "ShopHome",
  components: {
    Header,
    pageTitle,
    orderItem
  },
  mixins: [Global, OrderReaded],
  data: function() {
    return {
      backBtn: true,
      startDate: "",
      endDate: "",
      date: "",
      stores: []
    };
  },
  async created() {
    this.stores = await this.extraReaded();
  }
};
</script>
